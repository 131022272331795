import {
  ApolloCache,
  ApolloClient,
  DefaultOptions,
  FieldPolicy,
  InMemoryCache,
} from '@apollo/client';
import * as Sentry from '@sentry/core';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import { v4 as uuid } from 'uuid';

import type { AppClientSlug } from '@oui/lib/src/appClientDirectory';
import apolloPossibleTypes, {
  type TypedTypePolicies,
} from '@oui/lib/src/types/apollo-types.generated';

import { makeClient } from '../lib/apollo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const merger: FieldPolicy<any> = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

// https://github.com/dotansimha/graphql-code-generator/issues/5025
const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      ouiUser(_, { toReference }) {
        return toReference({
          __typename: 'CurrentOuiUser',
        });
      },
      patientByPatientID(_, { args, toReference }) {
        return toReference({
          __typename: 'PatientProfile',
          patient: {
            ID: args?.patientID,
          },
        });
      },
      hopeKitItems: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        merge(existing = [], incoming: any[]) {
          return incoming ?? existing;
        },
      },
    },
  },
  ActivityEvent: {
    keyFields: ['activityEventID'],
  },
  ActivityEventInstance: {
    keyFields: ['activityEventInstanceID'],
  },
  KVResponse: {
    keyFields: ['context', 'key'],
  },
  Organization: {
    keyFields: ['ID'],
  },
  Composition: {
    keyFields: ['ID'],
  },
  CompositionSection: {
    keyFields: ['ID'],
  },
  CurrentOuiUser: {
    keyFields: [],
  },
  UserEntity: {
    keyFields: ['ID'],
  },
  LegacyUser: {
    keyFields: ['ID'],
    fields: {
      name: merger,
      demo: merger,
      address: merger,
    },
  },
  PatientProfile: {
    // TODO now we have ID directly on PatientProfile, we should add it to all existing queries
    // and then update the keyFields to ['ID']
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyFields: ['patient', ['ID'] as any],
    fields: {
      progress: {
        merge(existing, incoming) {
          return incoming ?? existing;
        },
      },
    },
  },
  YstProfile: {
    keyFields: ['ID'],
  },
  OuiAdmin: {
    keyFields: ['ID'],
    fields: {
      person: merger,
    },
  },
  Practitioner: {
    keyFields: ['ID'],
    fields: {
      person: merger,
    },
  },
  Role: {
    keyFields: ['ID'],
    fields: {
      productConfig: {
        merge(existing, incoming) {
          return merge({}, existing, incoming);
        },
      },
      progress: {
        merge(existing, incoming) {
          return incoming ?? existing;
        },
      },
    },
  },
  Patient: {
    keyFields: ['ID'],
    fields: {
      person: merger,
    },
  },
  PatientSupporter: {
    keyFields: ['supporterID', 'patientID'],
  },
  Practice: {
    keyFields: ['practiceID'],
    fields: {
      practiceValues: merger,
    },
  },
  SleepDiaryEntryPractice: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyFields: ['practiceValues', ['date'] as any],
    fields: {
      practiceValues: merger,
    },
  },
  WordPairingPractice: {
    keyFields: ['practiceID'],
    fields: {
      practiceValues: merger,
      wordPairing: merger,
    },
  },
  FileUpload: {
    keyFields: ['fileUploadID'],
  },
  HopeKitImage: {
    keyFields: ['hopeKitItemID'],
  },
  HopeKitVideo: {
    keyFields: ['hopeKitItemID'],
  },
  HopeKitQuote: {
    keyFields: ['hopeKitItemID'],
  },
  CMSSession: {
    keyFields: ['sessionID'],
  },
  CMSExchange: {
    keyFields: ['exchangeID'],
  },
  CMSApp: {
    keyFields: ['appID'],
  },
  CMSVariant: {
    keyFields: ['variantID'],
  },
  OrganizationMember: {
    // TODO since a user can belong to multiple organizations, userID isn't necessarily unique.
    // Need organizationID too once added to API
    keyFields: ['userID'],
    fields: {
      person: merger,
    },
  },
  QuizSetCollection: {
    keyFields: ['quizSetCollectionID'],
  },
  QuizSet: {
    keyFields: ['quizSetID'],
    fields: {
      items: {
        merge(existing, incoming) {
          return incoming ?? existing;
        },
      },
      legacyItems: {
        merge(existing, incoming) {
          return incoming ?? existing;
        },
      },
    },
  },
  QuizSetItemVideo: {
    keyFields: ['quizSetItemID'],
  },
  QuizSetItemChoice: {
    keyFields: ['quizSetItemID'],
  },
  YstNomination: {
    fields: { status: merger },
  },
};

export const createApolloCacheBase = ({
  addTypename,
  cacheClass = InMemoryCache,
}: { addTypename?: boolean; cacheClass?: typeof InMemoryCache } = {}) => {
  return new cacheClass({
    addTypename: addTypename ?? true,
    possibleTypes: apolloPossibleTypes.possibleTypes,
    typePolicies,
    dataIdFromObject: (object) => {
      if (object.__typename && object.ID) return `${object.__typename}:${object.ID}`;
      if (object.ID) return `${object.ID}`;
      if (object.__typename) {
        // if ID isn't the primary key, try {__typename}ID with correct casing
        const guessedIDKey = `${object.__typename[0].toLowerCase()}${object.__typename.slice(1)}ID`;
        if (object[guessedIDKey]) return `${object.__typename}:${object[guessedIDKey]}`;
      }
      return undefined;
    },
  });
};

export const createApolloClientBase = ({
  uri,
  cache = createApolloCacheBase(),
  baseClass,
  clientLocale,
  clientName,
  clientTimezone,
  clientVersion,
  connectToDevTools = false,
  defaultOptions = {},
  getAuthHeader: _getAuthHeader,
  isProduction,
  onLogout,
  subscriptionUri,
}: {
  uri: string;
  baseClass?: typeof ApolloClient;
  cache?: ApolloCache<unknown>;
  clientName: AppClientSlug;
  clientVersion: string;
  clientTimezone?: string;
  clientLocale?: string;
  connectToDevTools?: boolean;
  defaultOptions?: DefaultOptions;
  getAuthHeader: () => Promise<string> | string | undefined;
  isProduction: boolean;
  onLogout: (options: {
    apollo: ApolloClient<unknown>;
    setIsLoggingOut: (val: boolean) => void;
  }) => Promise<unknown>;
  subscriptionUri?: string;
}) => {
  let logoutPromise: Promise<unknown> | null = null;
  const client = makeClient<object>({
    isProduction,
    baseClass,
    connectToDevTools,
    cache,
    defaultOptions: merge<DefaultOptions, DefaultOptions>(
      {
        mutate: {},
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          // We want to rely on the cache when a component re-renders (not remounts) rather
          // than send a new network request on every render after a mutation that touched the cached object
          // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
          nextFetchPolicy: 'cache-first',
          errorPolicy: 'all',
        },
      },
      defaultOptions,
    ),
    uri,
    subscriptionOptions:
      !subscriptionUri || process.env.NODE_ENV === 'test'
        ? undefined
        : {
            uri: subscriptionUri,
            connectionParams: async () => {
              return {
                Authorization: await _getAuthHeader(),
              };
            },
          },
    request: async (operation) => {
      const authHeader = await _getAuthHeader();
      if (authHeader) {
        operation.setContext({
          headers: {
            ...operation.getContext()?.headers,
            authorization: authHeader,
          },
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      operation.setContext((currentContext: Record<string, any>) => ({
        headers: pickBy(
          {
            'X-Oui-Client': clientName,
            'X-RequestID': uuid(),
            'X-Oui-Client-Version': clientVersion,
            'X-Client-Timezone': clientTimezone,
            'X-Client-Locale': clientLocale,
            // Allow individual requests to override headers if necessary
            // (e.g. for CMS spoofing requests as patient during content testing)
            ...currentContext.headers,
          },
          (v) => v !== null && typeof v !== 'undefined',
        ),
      }));
    },
    onError: ({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          const { message, locations, path, extensions } = error;
          if (
            (extensions?.exception as { extendedMessage?: string } | undefined)?.extendedMessage ===
              'INVALID_TOKEN' ||
            // @ts-expect-error
            error?.extendedMessage === 'INVALID_TOKEN'
          ) {
            if (!logoutPromise) {
              logoutPromise = onLogout({
                apollo: client,
                // This should, in theory, come from LogoutContext to make sure that we don't
                // refetch queries during our logout, but since this specific logout behavior is
                // really an edge case when a user's token is already invalid, we don't really
                // get much by implementing setIsLoggingOut faithfully
                // Original issue: https://github.com/ouihealth/oui-aviva/commit/413e7deca5c0369718fc66d9ef3f235b1530881c
                setIsLoggingOut: () => {},
              });

              logoutPromise
                .then(() => {
                  logoutPromise = null;
                })
                .catch(() => {
                  logoutPromise = null;
                });
            }
          }

          const bodyStr = message.split('body: ')[1];
          if (bodyStr && bodyStr.startsWith('{')) {
            // @ts-expect-error
            error.body = JSON.parse(bodyStr);
          }

          const requestID = operation.getContext().headers['X-RequestID'];

          const extra = isProduction
            ? { requestID }
            : {
                requestID,
                error,
                originalError: error.originalError,
                operationName: operation.operationName,
              };
          Sentry.captureMessage(
            `[GraphQL error]: Message: ${message}, Location: ` + `${locations}, Path: ${path}`,
            { extra },
          );
        });
      }
      if (networkError) {
        Sentry.captureException(networkError);
      }
    },
  });
  return client;
};
